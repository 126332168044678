import ErrorState from '@rio-cloud/rio-uikit/ErrorState';
import { FormattedMessage } from 'react-intl';

export type InternalErrorStateProps = {
    reloadTriggerFunction?: () => void;
    className?: string;
    fullWidth?: boolean;
};

const InternalErrorState = ({ reloadTriggerFunction, className, fullWidth }: InternalErrorStateProps) => (
    <ErrorState
        headline={<FormattedMessage id="common.error.emptyState.headline" />}
        message={<FormattedMessage id="common.error.emptyState.message" />}
        buttons={[
            {
                text: <FormattedMessage id="common.error.emptyState.button" />,
                onClick: reloadTriggerFunction,
            },
        ]}
        innerClassName={className}
        fullWidth={fullWidth}
    />
);

export default InternalErrorState;
