import { extractLanguage } from './lang/lang';
import { configureFetchDisplayMessages } from './lang/services';
import { configureMockUserManager, configureUserManager, createUserManager, SessionRenewedResult } from './login/login';
import { accessToken } from './tokenHandling/accessToken';
import { trace } from './setup/trace';
import { attemptInitialSignIn } from './setup/oauth';
import {
    config,
    isTraining,
    isWorkshopParentFrontend,
    PARENT_FRONTEND_CUSTOMER,
    PARENT_FRONTEND_PARAM,
    PARENT_FRONTEND_WORKSHOP,
    TRAINING_PARAM,
} from '../config';
import { reportErrorToSentry } from './setup/sentry';
import { store } from './setup/store';
import { accessTokenStored, idTokenStored } from './tokenHandling/tokenSlice';
import { userProfileObtained, userSessionExpired, userSessionRenewed } from './login/loginSlice';
import { getLocale } from './lang/langSlice';
import { UserManager } from 'oidc-client-ts';
import axios from 'axios';

const WORKSHOP_USER_TOKEN_PARAM = 'wu_token';
const WORKSHOP_USER_LOCALE_PARAM = 'wu_locale';
const CURRENT_UIKIT_STYLE = 'rio';

export interface OAuthConfig {
    onSessionExpired: Function;
    onSessionRenewed: Function;
}

export const main = async (renderApp: Function) => {
    const fetchDisplayMessages = configureFetchDisplayMessages(store);

    // We want the `<html lang>` attribute to be synced with the
    // language currently displayed
    store.subscribe(() => {
        const lang = extractLanguage(getLocale(store.getState()));
        const html = document.querySelector('html');

        if (html && lang && html.getAttribute('lang') !== lang) {
            html.setAttribute('lang', lang);
        }
    });

    removeOidcUserIfTrainingSwitched();
    window.localStorage.setItem(TRAINING_PARAM, String(isTraining()));

    if (urlContainsWorkshopUserToken()) {
        // this option will be used for testing purposes only
        trace('*** Running in Workshop User MODE => with forced token! ***');
        setUiKitTheme('man');
        performWorkshopUserTokenAuth(fetchDisplayMessages);
    } else if (isWorkshopParentFrontend()) {
        trace('*** Running in Workshop User MODE ***');
        setUiKitTheme('man');
        window.localStorage.setItem(PARENT_FRONTEND_PARAM, PARENT_FRONTEND_WORKSHOP);
        await performUserAuth(fetchDisplayMessages, getWorkshopUserLocaleFromUrlParam());
    } else {
        trace('*** Running in RIO User MODE ***');
        setUiKitTheme('rio');
        window.localStorage.setItem(PARENT_FRONTEND_PARAM, PARENT_FRONTEND_CUSTOMER);
        await performUserAuth(fetchDisplayMessages);
    }

    try {
        renderApp();
    } catch (error) {
        trace('could not start application', error);
        reportErrorToSentry(error);
    }
};

const removeOidcUserIfTrainingSwitched = () => {
    const localStorageValue = window.localStorage.getItem(TRAINING_PARAM);
    if (localStorageValue !== null && localStorageValue !== String(isTraining())) {
        // if the training changed, then we need to clear the oidc-client value from session storage,
        // otherwise we could be loading the user with wrong 'isServiceCareTraining' value
        for (const key in sessionStorage) {
            if (key.startsWith('oidc.user:')) {
                sessionStorage.removeItem(key);
            }
        }
    }
};

const getWorkshopUserTokenFromUrlParam = (): string | undefined => {
    const currentUrl = new URL(window.location.href);
    return currentUrl.searchParams.get(WORKSHOP_USER_TOKEN_PARAM) || undefined;
};

const getWorkshopUserLocaleFromUrlParam = (): string | undefined => {
    const currentUrl = new URL(window.location.href);
    return currentUrl.searchParams.get(WORKSHOP_USER_LOCALE_PARAM) || undefined;
};

const setUiKitTheme = (style: string) => {
    if (style !== CURRENT_UIKIT_STYLE) {
        const link = document.getElementById('uikit-css-link') as HTMLLinkElement;
        link.href = link.href.replace(`/${CURRENT_UIKIT_STYLE}-uikit.css`, `/${style}-uikit.css`);
    }
};

const urlContainsWorkshopUserToken = () => window.location.href.includes(`${WORKSHOP_USER_TOKEN_PARAM}=`);

const performWorkshopUserTokenAuth = (fetchDisplayMessages: Function) => {
    const token = getWorkshopUserTokenFromUrlParam();
    const locale = getWorkshopUserLocaleFromUrlParam();
    // set token for all axios requests
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    fetchDisplayMessages(locale);
};

const performUserAuth = async (fetchDisplayMessages: Function, overrideLocale: string | undefined = undefined) => {
    const oauthConfig = {
        onSessionExpired: () => {
            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onSessionRenewed: (result: SessionRenewedResult) => {
            trace('index.onTokenRenewed', result);

            // set token for all axios requests
            axios.defaults.headers.common.Authorization = `Bearer ${result.accessToken}`;

            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(result.idToken));
            store.dispatch(userProfileObtained(result.profile));

            store.dispatch(userSessionRenewed());

            // You will need to get the user language yourself then
            // you may fetch the suitable messages. Depending
            // on when and from where you fetch the user settings you might
            // want to employ a loading spinner while the request is ongoing.
            fetchDisplayMessages(overrideLocale !== undefined ? overrideLocale : result.locale);
        },
    } as OAuthConfig;

    const isAllowedToMockAuth = import.meta.env.DEV;
    const userManager: UserManager =
        isAllowedToMockAuth && config.login.mockAuthorization
            ? configureMockUserManager(oauthConfig)
            : configureUserManager(oauthConfig, createUserManager());

    try {
        await userManager.clearStaleState();
        await attemptInitialSignIn(userManager);
    } catch (error) {
        trace('could not perform RIO SignIn', error);
        reportErrorToSentry(error);
    }
};
