import Switch from '@rio-cloud/rio-uikit/Switch';
import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationEmailId } from '../../generated/GeneratedApiTypes';
import useNotificationSettings from './useNotificationSettings';
import InternalErrorState from './InternalErrorState';
import ButtonWithLoadingInfo from './ButtonWithLoadingInfo';
import { gaPush } from '../../configuration/setup/googleAnalytics';

const NotificationSettingsSkeletonScreen = () => {
    const SingleItemSkeleton = () => (
        <>
            <div className="display-flex justify-content-between margin-top-20">
                <ContentLoader height={17} width={300} />
                <ContentLoader height={20} width={35} />
            </div>
            <ContentLoader height={10} width={400} className="margin-top-2" />
        </>
    );
    return (
        <div data-testid="notification-settings-skeleton-screen" className="display-flex flex-column">
            <ContentLoader height={25} width={370} />
            <ContentLoader height={12} width={400} className="margin-top-5 margin-bottom-15" />
            <SingleItemSkeleton />
            <div className="margin-top-5" />
            <SingleItemSkeleton />
            <SingleItemSkeleton />
            <SingleItemSkeleton />
        </div>
    );
};

const NotificationSettings = () => {
    const {
        isLoading,
        hasErrors,
        notificationSettings,
        isUpdatingNotificationSettings,
        setIsUpdatingNotificationSettings,
        setNotificationSettings,
        triggerReload,
    } = useNotificationSettings();
    const [allNotificationSwitchClicked, setAllNotificationSwitchClicked] = useState<boolean>(false);
    const allEnabled = notificationSettings?.every((notification) => notification.enabled === true);

    const handleSingleNotificationSwitch = (email_id: NotificationEmailId | undefined, enabled: boolean) => {
        const newNotificationSettings = [...notificationSettings];
        newNotificationSettings.forEach((notification) => {
            if (notification.email_id === email_id) notification.enabled = enabled;
        });
        setAllNotificationSwitchClicked(false);
        setNotificationSettings(newNotificationSettings);
    };

    const handleAllNotificationSwitch = () => {
        const newNotificationSettings = [...notificationSettings];
        newNotificationSettings.forEach((notification) => (notification.enabled = !allEnabled));
        setAllNotificationSwitchClicked(true);
        setNotificationSettings(newNotificationSettings);
    };

    const saveChanges = () => {
        setIsUpdatingNotificationSettings(true);
        gaPush({
            event: 'save_notification_settings',
            parameters: {
                element_name: 'NotificationSettings',
                component_name: 'Button',
                trigger: 'click',
            },
            userProps: {},
        });
    };

    const renderFooter = () => {
        return (
            <div className="text-right padding-top-20">
                <ButtonWithLoadingInfo
                    isLoading={isUpdatingNotificationSettings}
                    onClick={saveChanges}
                    textKey="notificationSettings.save_changes"
                />
            </div>
        );
    };

    if (hasErrors) {
        return <InternalErrorState reloadTriggerFunction={triggerReload} />;
    }

    if (isLoading) {
        return <NotificationSettingsSkeletonScreen />;
    }

    return (
        <>
            <div className="margin-bottom-20">
                <h3 className="margin-bottom-0 margin-top-0">
                    <FormattedMessage id={'notificationSettings.header'} />
                </h3>
                <span className="text-color-dark">
                    <FormattedMessage id={'notificationSettings.subHeader'} />
                </span>
            </div>

            <div className="margin-bottom-20">
                <div className="display-flex justify-content-between">
                    <div>
                        <FormattedMessage id={'notificationSettings.receive_all_notifications'} />
                        <br />
                        <span className="text-color-dark text-size-12">
                            <FormattedMessage id={'notificationSettings.common.recommendedSetting'} />
                        </span>
                    </div>

                    <Switch onChange={handleAllNotificationSwitch} checked={allEnabled} />
                </div>

                <hr className="margin-bottom-10 margin-top-10" />

                {notificationSettings.map(({ email_id, enabled, periodicity }) => {
                    return (
                        <div className="display-flex justify-content-between margin-bottom-20" key={email_id}>
                            {periodicity === 'WEEKLY' ? (
                                <div>
                                    <FormattedMessage id={'notificationSettings.weekly_overview'} />
                                    <br />
                                    <span className="text-color-dark text-size-12">
                                        <FormattedMessage id={'notificationSettings.weekly_overview.description'} />
                                        <FormattedMessage id={'notificationSettings.common.recommendedSetting'} />
                                    </span>
                                </div>
                            ) : (
                                <FormattedMessage id={`notificationSettings.${email_id?.toLowerCase()}`} />
                            )}
                            <Switch
                                key={email_id}
                                onChange={handleSingleNotificationSwitch.bind(null, email_id, !enabled)}
                                checked={allNotificationSwitchClicked ? allEnabled : enabled}
                            />
                        </div>
                    );
                })}
            </div>

            <hr className="margin-bottom-10 margin-top-10" />

            {renderFooter()}
        </>
    );
};

export default NotificationSettings;
