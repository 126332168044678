import axios from 'axios';
import { useEffect, useState } from 'react';
import { config } from '../../config';
import { NotificationSettings } from '../../../src/generated/GeneratedApiTypes';
import { useIntl } from 'react-intl';
import { SHOW_NOTIFICATION, sendMessage } from './MessageHandler';

const useNotificationSettings = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [reloadState, setReloadState] = useState<{}>({});
    const [isUpdatingNotificationSettings, setIsUpdatingNotificationSettings] = useState<boolean>(false);
    const [notificationSettings, setNotificationSettings] = useState<NotificationSettings[]>([
        { type: 'EMAIL', email_id: 'CUSTOMER_APPOINTMENT_CONFIRMATION', audience: 'SERVICECARE', enabled: false },
    ]);

    const intl = useIntl();

    const triggerReload = () => {
        setHasErrors(false);
        setReloadState({});
    };

    useEffect(() => {
        axios
            .get(config.backend.BACKEND_URI + '/settings')
            .then((res) => {
                setNotificationSettings(res.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching notification-settings', error);
                setHasErrors(true);
            });
    }, [reloadState]);

    useEffect(() => {
        if (isUpdatingNotificationSettings) {
            setIsUpdatingNotificationSettings(false);
            axios
                .post(config.backend.BACKEND_URI + '/settings', notificationSettings)
                .then(() => {
                    if (notificationSettings?.every((notification) => notification.enabled === true))
                        sendMessage({
                            type: SHOW_NOTIFICATION,
                            payload: {
                                title: intl.formatMessage({ id: 'notificationSettings.saved_successfully' }),
                                // eslint-disable-next-line max-len
                                type: 'SUCCESS',
                            },
                        });
                    else
                        sendMessage({
                            type: SHOW_NOTIFICATION,
                            payload: {
                                title: intl.formatMessage({ id: 'notificationSettings.saved_successfully' }),
                                // eslint-disable-next-line max-len
                                description: intl.formatMessage({
                                    id: 'notificationSettings.saved_successfully.description',
                                }),
                                type: 'SUCCESS',
                            },
                        });
                    setNotificationSettings(notificationSettings);
                })
                .catch((error) => {
                    sendMessage({
                        type: SHOW_NOTIFICATION,
                        payload: {
                            title: intl.formatMessage({ id: 'notificationSettings.save_failed' }),
                            description: intl.formatMessage({ id: 'notificationSettings.save_failed.description' }),
                            type: 'ERROR',
                        },
                    });
                    console.error(error);
                });
        }
    }, [isUpdatingNotificationSettings]);

    return {
        isLoading,
        hasErrors,
        notificationSettings,
        isUpdatingNotificationSettings,
        setIsUpdatingNotificationSettings,
        setNotificationSettings,
        triggerReload,
    };
};

export default useNotificationSettings;
