import { createHashRouter, createRoutesFromElements, Route } from 'react-router-dom';
import App from '../features/app/App';
import { ErrorBoundary } from '../features/app/ErrorBoundary';
import NotificationSettings from '../features/app/NotificationSettings';

export const router = createHashRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
            <Route path="/" errorElement={<ErrorBoundary />} element={<NotificationSettings />} />
        </Route>
    )
);
